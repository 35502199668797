
.text-radio-box {
    margin: 1em;
    display: flex;
    justify-content: center;
}

.text-radio-box input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  
.text-radio-box label {
    font: 1.5em Arial, sans-serif;
    font-weight: bold;

    width: fit-content;
    height: 2em;
    box-sizing: initial;

    background: #fff;
    text-align: center;
    border-radius: .4rem;
    line-height: 2em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}
  
.text-radio-box label:hover {
    background-color: #007bff;
    cursor: pointer;
    color: white;
}

  
.text-radio-box input[type="radio"]:checked + label {
    background-color: blue;
    color: white;
}

.vl {
    border-left: 2px solid black;
    margin-left: 0.5em;
    margin-right: 0.5em;
}
