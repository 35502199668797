.question-box {
    display: flex;
    margin: 1em;
    justify-content: center;
}

.question-label {
    font: 1.5em Arial, sans-serif;
    align-self: center;
    font-weight: bold;
    text-align: center;
}
