.survey-form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

h1 {
    color: blue;
    margin: 0;
    font-size: 4.5em;
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

h2 {
    margin: 0;
    text-align: center;
    font-size: 1.5em;

}

h3 {
    margin: 0;
    text-align: center;
    font-size: 1.2em;
}

h4 {
    margin: 0;
    text-align: left;
    font-size: 1em;
}

.left-align{
    text-align: left;
}

sub {
    font-size: x-small;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 2em;
    width: 75%;
    max-width: 800px;
}

.footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2em;
    width: 75%;
    max-width: 800px;
}

.new-line {
    height: 1em;
}

.vyscore {
    color: blue;
}

.submit-div {
    display: flex;
    justify-content: center;
}

.submit-btn {
    height: 2em;
    font: 1.5em Arial, sans-serif;
    font-weight: bold;
    background-color: white;
    border-radius: .4rem;
    border-style:solid;
    border-color: rgba(0, 0, 255, 0.3);
}

.submit-btn:enabled {
    background-color: white;
    border-color: blue;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

}

.submit-btn:hover:enabled {
    background-color: blue;
    cursor: pointer;
    color: white;
}

.vyscore {
    font-size: 8em;
    margin: 0.5em;
    font-weight: bold;
}

.response {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}


.red {
    color: red;
}

.green {
    color: green;
}

.blue {
    color:blue;
}

.ven-image{
    width:50%;
}