
.input-box {
    margin: 1em;
    display: flex;
    justify-content: center;
}


.special-label {
    display: none;
}

.form-control {
    font-size: 1.25rem;
}

.phone-container {
    width: 256px;
    font-family: inherit;
}

