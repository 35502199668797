.table-wrapper {
    width: 70%;
}

.container .button-container {
    margin: 1em;
}

.news-text-area {
    resize: none;
}

.news-date-picker {
    margin-bottom: 1em;
    margin-top: 1em;
}

.margin-top {
    margin-top: 1em;
}

.margin-bottom {
    margin-bottom: 1em;
}

.hover:hover {
    cursor: pointer;
    background-color: #dee2e6;
}